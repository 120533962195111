<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-alert-outline
      </v-icon> Project Issues
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="showSyncButton"
          text
          outlined
          class="sync-btn"
          @click="syncProjectIssuesFromPartner()"
        >
          <v-icon
            color="primary"
            size="24"
            left
          >
            mdi-cloud-sync-outline
          </v-icon>
          <span class="sync-btn-txt"> Sync Autodesk </span>
        </v-btn>
        <v-btn
          v-if="!accessCheck('project_member')"
          color="#000"
          @click="showCreateDialog = true"
        >
          <v-icon class="mr-2">
            mdi-plus-circle-outline
          </v-icon>
          Create Issue
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="partnerCheck()"
        class="info-text"
      >
        *Indicates from partner account
      </p>
      <v-data-table
        :headers="filteredHeaders"
        :items="projectIssues"
        :search="search"
        disable-sort
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on,attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.title }}<span
                    v-if="item.database === false"
                    class="partner-project"
                  >*</span></span>
                </template>
                <span v-if="item.description !== null">Description:- <br> {{ item.description }}</span>
                <span v-else>Description:- <br> N/A</span>
              </v-tooltip>
            </td>
            <td>
              <font
                class="status-style"
                :color="getHrwColor(item.high_risk_work)"
              >
                {{ item.high_risk_work | displayHighRisk }}
              </font>
            </td>
            <td>
              <font v-if="item.company !== null">
                {{ item.company.name }}
              </font><font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.start_date !== null">
                {{ item.start_date | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.due_date !== null">
                {{ item.due_date | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                class="status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status | statusFormat }}
              </font>
            </td>
            <td class="premium">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                :disabled="(subscriptionCheck('trial'))"
                @click.stop
                @click="getProjectIssueCheckIns(item.id, item.title)"
              >
                <v-icon>mdi-account-eye-outline</v-icon>
              </v-btn>
              <span
                v-if="(subscriptionCheck('trial'))"
                class="premium-container"
              >
                <v-icon class="premium-style">
                  mdi-crown-circle
                </v-icon>
              </span>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="editProjectIssueStatus(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </td>
            <td v-if="!accessCheck('project_member')">
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click="deleteProjectIssue(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <br>
    <center-spinner
      :loading="loading"
    />
    <partner-auth-check
      ref="partnerAuth"
      @partner-authorized="getProjectIssuesFromPartner"
    />
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <new-project-issue
      v-if="showCreateDialog"
      @closed="showCreateDialog = false"
      @success="getProjectIssues"
    />
    <delete-project-issue
      v-if="showDeleteDialog"
      :title="'Delete this project issue ?'"
      :message="'Action will permanently remove the project issue from the list, Do you want to continue ?'"
      @closed="showDeleteDialog = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-project-issue-details
      v-if="showEditStatusDialog"
      :project-issue-details="projectIssueDetails"
      @closed="showEditStatusDialog = false"
      @success="getProjectIssues"
    />
    <project-issue-check-ins
      v-if="showProjectIssueCheckIns"
      :project-id="projectId"
      :project-issue-id="projectIssueId"
      :project-issue-name="projectIssueName"
      @closed="showProjectIssueCheckIns = false"
    />
  </v-container>
</template>

<script>
import Constants from 'src/constants';
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import global from 'src/mixins/global';
import PartnerAuthCheck from 'src/views/dashboard/partnerauth/PartnerAuthCheck';
import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
import NewProjectIssue from './NewProjectIssue.vue';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import EditProjectIssueDetails from './EditProjectIssueDetails.vue';
import ProjectIssueCheckIns from './ProjectIssueCheckIns.vue';

export default {
  name: 'ProjectIssues',
  components: {
    'center-spinner': spinner,
    'partner-auth-check': PartnerAuthCheck,
    'overlay-alert': OverlayAlert,
    'new-project-issue': NewProjectIssue,
    'delete-project-issue': DeleteDialog,
    'edit-project-issue-details': EditProjectIssueDetails,
    'project-issue-check-ins': ProjectIssueCheckIns,
  },
  filters: {
    convertToLocal (date) {
      return moment(date).local().format('Do MMMM YYYY');
    },
    displayHighRisk (status) {
      if (status === true) {
        return 'Yes*';
      } else {
        return 'No';
      }
    },
    statusFormat (val) {
      if (val === 'active') {
        return 'Active*';
      } else if (val === 'pending') {
        return 'Pending';
      } else if (val === 'completed') {
        return 'Completed*';
      } else {
        return val;
      }
    },
  },
  mixins: [global],
  data () {
    return {
      search: '',
      loading: false,
      headers: [
        { text: 'Title', align: 'start', value: 'title' },
        { text: 'High Risk Work', value: 'high_risk_work' },
        { text: 'Assigned To', value: 'assigned_to' },
        { text: 'Start Date', value: 'start_date' },
        { text: 'Due Date', value: 'due_date' },
        { text: 'Status', value: 'status' },
        { text: 'Check-Ins', value: 'check-ins' },
        { text: 'Edit', value: 'edit' },
        { text: 'Delete', value: 'delete' },
      ],
      showProgress: false,
      processAlert: '',
      showCreateDialog: false,
      showEditStatusDialog: false,
      showProjectIssueCheckIns: false,
      projectId: '',
      projectIssueId: '',
      projectIssueName: '',
      projectIssueStatus: '',
      showDeleteDialog: false,
      projectIssueDetails: {},
    };
  },
  computed: {
    projectIssues () {
      return this.$store.getters['projects/getProjectIssues'];
    },
    projectDetails () {
      return this.$store.getters['projects/getProjectDetails'];
    },
    showSyncButton () {
      if (this.projectDetails.database === false) {
        return true;
      }
      return false;
    },
    filteredHeaders () {
      if (this.accessCheck('project_member')) {
        return this.headers.filter((item) => item.text !== 'Edit' && item.text !== 'Delete');
      } else {
        return this.headers;
      }
    },
  },
  async mounted () {
    this.loading = true;
    await this.subscriptionCheck('active') && await this.getProjectIssues();
    this.loading = false;
  },
  methods: {
    async getProjectIssues () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchProjectIssues', {
        projectId: this.$route.params.projectId,
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    getHrwColor (value) {
      if (value === true) return '#C62828';
      else { return '#2E7D32'; }
    },
    getStatusColor (val) {
      if (val === 'active') {
        return '#2E7D32';
      } else if (val === 'pending') {
        return '#C62828';
      } else if (val === 'completed') {
        return '#2E7D32';
      } else {
        return '#37474F';
      }
    },
    syncProjectIssuesFromPartner () {
      this.$refs.partnerAuth.isPartnerAuthorized();
    },
    async getProjectIssuesFromPartner () {
      this.showProgress = true;
      this.processAlert = 'Syncing...';
      await this.$store.dispatch('partners/fetchProjectIssuesFromPartner', {
        projectId: this.$route.params.projectId,
      }).then(response => {
        this.showProgress = false;
        this.getProjectIssues();
      }).catch(() => {
        this.showProgress = false;
      });
    },
    deleteProjectIssue (id) {
      this.projectIssueId = id;
      this.showDeleteDialog = true;
    },
    async deleteConfirmed () {
      this.loading = true;
      this.showDeleteDialog = false;
      await this.$store.dispatch('projects/deleteProjectIssue', {
        projectIssueId: this.projectIssueId,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Project issue deleted successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.showDeleteDialog = false;
        this.loading = false;
        this.getProjectIssues();
      }).catch(() => {
        this.loading = false;
      });
    },
    editProjectIssueStatus (details) {
      this.projectIssueDetails = details;
      this.showEditStatusDialog = true;
    },
    getProjectIssueCheckIns (id, title) {
      this.projectIssueId = id;
      this.projectId = this.$route.params.projectId;
      this.projectIssueName = title;
      this.showProjectIssueCheckIns = true;
    },
  },
};
</script>

<style scoped>
.title-style{
  color: #37474F;
}
.icon-style{
  color: #37474F;
  font-size: 45px;
}
.status-style{
  font-weight: bold;
  text-transform: capitalize;
}
.v-data-table { background-color: #ECEFF1; }
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.sync-btn-txt {
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  margin-left: 5px;
  font-size: 15px;
}
.sync-btn {
  margin-left: 8px;
  margin-right: 10px;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.partner-project {
  color: #FF3700;
  font-weight: bold;
}
.table-projects {
    border-radius: 0px !important;
    background: #fff;
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-top: none;
  }
  .project-list-card {
    box-shadow: none !important;
  }
  .table-projects tbody tr:nth-of-type(even) {
    background: #fff !important;
  }
  .table-projects tbody tr:nth-of-type(odd):hover {
    background: #fff !important;
  }
  .action-btn {
    background: #fff !important;
  }
  .filter-card {
    border: 1px solid #ccc !important;
    padding: 15px;
  }
  .table-top-border{
    border: 1px solid #ccc;
    border-bottom: none;
    background: #edf6f9;
  }
  .info-text{
    margin-bottom: 0px !important;
  }
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left:0;
}
.premium{
  position: relative;
}
</style>
