import { render, staticRenderFns } from "./ProjectSecondNavDrawer.vue?vue&type=template&id=23260b34&scoped=true"
import script from "./ProjectSecondNavDrawer.vue?vue&type=script&lang=js"
export * from "./ProjectSecondNavDrawer.vue?vue&type=script&lang=js"
import style0 from "./ProjectSecondNavDrawer.vue?vue&type=style&index=0&id=23260b34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23260b34",
  null
  
)

export default component.exports