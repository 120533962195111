<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-chart-box-outline
      </v-icon> Project Schedules
    </h2>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <br>
      <div class="no-file-alert">
        <h4 v-if="showNoBudgetAlert">
          No budgets found.
        </h4>
      </div>
      <v-col
        class="text-right table-top-border"
      >
        <v-btn
          v-if="!showNoBudgetAlert"
          color="#000"
          @click="saveChanges()"
        >
          <v-icon class="mr-2">
            mdi-content-save-outline
          </v-icon>
          Save
        </v-btn>
      </v-col>
      <div
        id="gantt_here"
      />
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <budget-details
      v-if="showBudgetDetails"
      :budget-id="budgetId"
      @closed="showBudgetDetails = false"
      @update-budget="editBudgetDetails"
    />
    <edit-budget-details
      v-if="showEditDialog"
      :budget-id="budgetId"
      @closed="showEditDialog = false"
      @budget-details-updated="getProjectBudgets"
    />
  </v-container>
</template>

<script>
  import gantt from 'dhtmlx-gantt';
  import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import BudgetDetails from './BudgetDetails.vue';
  import EditBudgetDetails from '../budgets/EditBudgetDetails.vue';
  import Constants from 'src/constants';

  export default {
    name: 'ProjectSchedulingChart',
    components: {
      'centre-spinner': spinner,
      'budget-details': BudgetDetails,
      'edit-budget-details': EditBudgetDetails,
    },
    data () {
      return {
        tasks: {
          data: [],
          links: [],
        },
        coloumns: [
          { name: 'text', label: 'Budgets', resize: true, align: 'center', width: '*' },
          { name: 'start_date', label: 'Start Date', resize: true, align: 'center', width: '*' },
          { name: 'end_date', label: 'End Date', resize: true, align: 'center', width: '*' },
        ],
        loading: false,
        showBudgetDetails: false,
        budgetId: '',
        showEditDialog: false,
      };
    },
    computed: {
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      projectBudgets () {
        const list = this.$store.getters['projects/getProjectBudgets'];
        // const sortedList = list.sort((a, b) => {
        //   return new Date(a.planned_start_date) - new Date(b.planned_start_date);
        // });
        return list;
      },
      projectBudgetLinks () {
        return this.$store.getters['projects/getProjectBudgetLinks'];
      },
      showNoBudgetAlert () {
        if (this.projectBudgets.length === 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      await this.getProjectBudgets();
    },
    methods: {
      async getProjectBudgets () {
        this.loading = true;
        this.$store.dispatch('projects/clearStoredBudgetLinks');
        await this.$store.dispatch('projects/fetchProjectBudgets', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.getBudgetsScheduleLinks();
        }).catch(() => {
          this.loading = false;
        });
        // this.loading = false;
      },
      async getBudgetsScheduleLinks () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchBudgetsScheduleLinks', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          this.initGantt();
        }).catch(() => {
          this.loading = false;
        });
      },
      getRandomColor () {
        const colors = ['#00695C', '#01579B', '#E65100', '#C62828', '#37474F'];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
      },
      initGantt () {
        this.loading = true;
        this.tasks.data = [];
        this.tasks.links = [];
        gantt.config.columns = this.coloumns;
        if (this.showNoBudgetAlert === true) {
          this.loading = false;
          return;
        }
        this.tasks.links = this.projectBudgetLinks;
        for (const budget of this.projectBudgets) {
          if (this.tasks.data.some(item => item.id === budget.id)) {
            return;
          }
          this.tasks.data.push({
            id: budget.id,
            text: budget.name,
            start_date: budget.planned_start_date !== null ? new Date(budget.planned_start_date) : null,
            end_date: budget.planned_end_date !== null ? new Date(budget.planned_end_date) : null,
            progress: this.getProgressPercentage(budget.quantity, budget.time_sheet_hours),
            color: this.getRandomColor(),
          });
        }
        this.tasks.links = this.projectBudgetLinks;
        gantt.templates.task_text = function (start, end, task) {
          return task.text + ' (' + task.progress + '%)';
        };
        gantt.config.project_start = this.projectDetails.start_date !== null ? this.projectDetails.start_date : 'N/A';
        gantt.config.project_end = this.projectDetails.end_date !== null ? this.projectDetails.start_end : 'N/A';
        gantt.config.drag_progress = false;
        gantt.config.drag_move = false;
        gantt.config.drag_resize = false;
        gantt.config.details_on_dblclick = false;
        gantt.config.resize_dnd = true;
        gantt.config.date_format = '%d %M %Y';
        gantt.config.resize_rows = true;
        gantt.config.show_grid = true;
        gantt.config.resize_columns = true;
        gantt.config.grid_elastic_columns = true;
        gantt.config.smart_rendering = true;
        gantt.config.autosize = true;

        gantt.init('gantt_here');

        gantt.clearAll();

        gantt.parse(this.tasks);
        this.loading = false;

        gantt.attachEvent('onAfterLinkAdd', (id, link) => {
          if (link) {
            this.$store.dispatch('projects/storeProjectBudgetLinks', link);
          }
        });
        gantt.attachEvent('onAfterLinkDelete', (id) => {
          if (id) {
            this.$store.dispatch('projects/deleteStoredBudgetLink', id);
          }
        });

        gantt.attachEvent('onTaskClick', (id, e) => {
          if (e.target.classList.contains('gantt_task_content')) {
            this.budgetId = id;
            this.showBudgetDetails = true;
            e.preventDefault();
            return false;
          }
          return true;
        });
        this.loading = false;
      },
      async saveChanges () {
        this.loading = true;
        const budgetLinks = this.projectBudgetLinks;
        await this.$store.dispatch('projects/storeSavedProjectBudgetLinks', {
          projectId: this.$route.params.projectId,
          budgetLinks: {
            schedules: budgetLinks,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project schedule saved successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectBudgets();
        }).catch(() => {
          this.loading = false;
        });
        // this.loading = false;
      },
      getProgressPercentage (totalHours, timeSheetHours) {
        const percentage = (timeSheetHours / totalHours) * 100;
        const roundedPercentage = percentage.toFixed(2);
        return roundedPercentage;
      },
      editBudgetDetails () {
        this.showBudgetDetails = false;
        this.showEditDialog = true;
      },
    },
  };
</script>

<style>
#gantt_here {
  height: 100vh;
  width: 100%;
}
.title-style {
  color: #37474F;
  margin-bottom: 25px;
}
.icon-style {
  color: #37474F !important;
  font-size: 45px !important;
}
.gantt_grid_scale,
.gantt_task_scale,
.gantt_task_vscroll {
background-color: #FFFF;
}

.gantt_grid_scale,
.gantt_task_scale,
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_grid_scale .gantt_grid_head_cell {
color: #37474F;
border-bottom: 1px solid #37474F;
font-weight: bold;
font-size: 12px;
}
.gantt_row.odd{
  background-color:#fff;
}
.gantt_row:hover {
  background-color: #ECEFF1 !important; /* Change to your desired color */
}
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
background-color: #ECEFF1;
}
.gantt_task .gantt_task_scale .gantt_scale_cell {
  border-right: 1px solid #37474F;
}
.gantt_task_line{
  border-color: rgba(0, 0, 0, 0.35); /* Black color with 25% alpha/opacity */
}
.gantt_task_line .gantt_task_progress {
  background-color: rgba(0, 0, 0, 0.35);
}
.gantt_task_line{
  background-color: #FF3700;
}
.gantt_task_line.gantt_task_content {
  color: #FFFF;
}
.gantt_task_progress {
   background: #299cb4;
}
.gantt_row.gantt_row_project{
  background-color:red;
  font-weight: bold;
}
.gantt_scale_line:nth-child(2){
  background-color:#fff;
}
.task_color_red .gantt_task_bar {
  background-color: #00695C !important;
}
.task_color_green .gantt_task_bar {
  background-color: #01579B !important;
}
.task_color_blue .gantt_task_bar {
  background-color: #E65100 !important;
}
.task_color_yellow .gantt_task_bar {
  background-color: #C62828 !important;
}
.task_color_orange .gantt_task_bar {
  background-color: #37474F !important;
}
.task_color_orange .gantt_task_bar {
  background-color: #FF3700 !important;
}
.gantt_grid .gantt_grid_head_cell {
  border-right: 1px solid #37474F !important; /* Adjust border style as needed */
  border-bottom: 1px solid #37474F;
}
.no-file-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
</style>
