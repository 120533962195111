<template>
  <div class="ml-5">
    <p>Team Members</p>
    <div class="d-flex team-members">
      <v-avatar
        v-if="projectMembers[0]"
        color="#686EE2"
        size="36"
      >
        <span class="avatar-text">{{ projectMembers[0] | filterProfile }}</span>
      </v-avatar>
      <v-avatar
        v-if="projectMembers[1]"
        color="#5C3E84"
        size="36"
      >
        <span class="avatar-text">{{ projectMembers[1] | filterProfile }}</span>
      </v-avatar>
      <v-avatar
        v-if="projectMembers[2]"
        color="#F35C6E"
        size="36"
      >
        <span class="avatar-text">{{ projectMembers[2] | filterProfile }}</span>
      </v-avatar>
      <v-avatar
        v-if="projectMembers[3]"
        color="#FFA87B"
        size="36"
      >
        <span class="avatar-text">{{ projectMembers[3] | filterProfile }}</span>
      </v-avatar>
      <v-avatar
        v-if="projectMembers.length > 4"
        color="#aaa"
        size="36"
      >
        <span class="avatar-text">+{{ projectMembers.length-4 }}</span>
      </v-avatar>
    </div>
    <p />
  </div>
</template>
<script>
export default {
    name: 'Teammebers',
    filters: {
        filterProfile (data) {
            if (data) {
                const firstName = data.first_name[0];
                const lastName = data.last_name[0];
                return firstName + lastName;
            }
            },
    },
    data () {
        return {
            teamMembers: [],
        };
    },
    computed: {
        projectMembers () {
        return this.$store.getters['projects/getProjectMembers'];
      },
    },
};
</script>
<style scoped>
.avatar-text {
  font-weight: bold;
  color: #fff;
}
.team-members .v-avatar{
  border-color: #fff !important;
  border: 2px solid;
  margin-left: -5px;
  cursor: pointer;
}
</style>
