<template>
  <v-dialog
    v-model="showModal"
    max-width="1200"
    persistent
  >
    <v-card
      class="base-card"
      outlined
      color="#fff"
    >
      <v-container
        id="regular-tables"
        fluid
      >
        <br>
        <h2 class="title-style">
          <v-icon
            class="icon-style"
            left
          >
            mdi-account-eye-outline
          </v-icon>Project Issue Check-Ins
          <v-btn
            class="close-button"
            color="#aaa"
            @click="showModal = false"
          >
            <span class="black--text">Close</span>
          </v-btn>
        </h2>
        <br>
        <p class="title-header">
          Issue Title:- <font
            class="title-name"
          >
            {{ projectIssueName }}
          </font>
        </p>
        <br>
        <v-card
          color="#fff"
          class="project-list-card "
        >
          <v-card-title class="filter-card">
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="5"
                sm="12"
              >
                <v-text-field
                  v-model="search"
                  :hint="isSearchAdded ? searchHint : ''"
                  persistent-hint
                  label="Search"
                  class="search-style"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                  @keyup.enter="getRecordsFromServer({itemsPerPage: 10, pageStart: 0})"
                  @keydown.esc="getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <p
            v-if="!isSearchAdded"
            class="info-text"
          >
            <v-icon>mdi-account-hard-hat-outline</v-icon> Indicates Project Work <span class="division-style"> | </span>
            <v-icon>mdi-account-wrench-outline</v-icon>Indicates Project Issue<span class="division-style"> | </span>
            <v-icon>mdi-tablet-cellphone</v-icon> Indicates Kiosks<span class="division-style"> | </span>
            <v-icon>mdi-swap-horizontal-bold</v-icon> Indicates Cost-Shift
          </p>
          <v-data-table
            :headers="checkIns.headers"
            :items="projectIssueCheckIns"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="id"
            disable-sort
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
            :loading="updateProgress"
            :server-items-length="totalRecords"
            class="elevation-1 table-projects"
            mobile-breakpoint="100"
            light
            @pagination="getRecordsFromServer"
          >
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr>
                <td>
                  {{ item.app_user.first_name }}  {{ item.app_user.last_name }}
                  <v-tooltip
                    v-if="dailyWorkHrLimitReached(item)"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#C62828"
                        v-on="on"
                      >
                        mdi-alert
                      </v-icon>
                    </template>
                    <span>Exceeded Daily Work Hour Limit</span>
                  </v-tooltip>
                </td>
                <td>{{ item.app_user.company.name }}</td>
                <td>
                  <font v-if="item.app_user.worker_category !== null">
                    {{ item.app_user.worker_category.name }}
                  </font>
                  <font v-else>
                    N/A
                  </font>
                </td>
                <td class="my-type-style">
                  <v-chip
                    outlined
                    color="#37474F"
                    dark
                  >
                    {{ item.type }}
                  </v-chip>
                </td>
                <td>
                  <v-icon
                    v-if="item.checkin_method_type === 'cost-shift'"
                    color="#2E7D32"
                    small
                  >
                    mdi-swap-horizontal-bold
                  </v-icon>
                  {{ item.checkin_time | convertToLocal }}
                </td>
                <td>
                  <font v-if="item.checkin_kiosk !== null">
                    <v-icon
                      small
                    >
                      mdi-tablet-cellphone
                    </v-icon>
                    {{ item.checkin_kiosk.location_name }}
                  </font>
                  <font v-if="item.checkin_kiosk === null && item.checkin_method_type === 'cost-shift'">
                    N/A
                  </font>

                  <font v-if="item.checkin_kiosk === null && item.checkin_method_type !== 'cost-shift'">
                    {{ item.checkin_gate.name }}
                  </font>
                </td>
                <td>
                  <font
                    v-if="item.checkout_time === null"
                    class="My-checkout-time"
                  >
                    Onsite*
                  </font>
                  <font v-else>
                    {{ item.checkout_time | convertToLocal }}
                    <v-tooltip
                      v-if="item.manual_checkout === true"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          color="#C62828"
                          v-on="on"
                        >
                          mdi-account-arrow-right
                        </v-icon>
                      </template>
                      <span>Checkout Was Done Manually</span>
                    </v-tooltip>
                  </font>
                </td>
                <td>
                  <font
                    v-if="item.checkout_time !== null"
                    class="status-style"
                    :color="getStatusColor(item.status)"
                  >
                    {{ item.status | statusFormat }}
                  </font>
                  <font v-else>
                    N/A
                  </font>
                </td>
                <td>
                  <v-btn
                    icon
                    small
                    @click.stop
                    @click="expand(!isExpanded)"
                  >
                    <v-icon
                      v-if="!isExpanded"
                    >
                      mdi-arrow-down-drop-circle
                    </v-icon>
                    <v-icon v-if="isExpanded">
                      mdi-arrow-up-drop-circle
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-card>
                  <v-toolbar
                    color="#37474F"
                    dark
                  >
                    <v-toolbar-title class="my-tool-bar-title-style">
                      More Details
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-list>
                    <v-list-item-content class="ml-3">
                      <v-list-item-title class="my-title-style">
                        <template>
                          <h4 class="option-style">
                            Status:- <font
                              v-if="item.checkout_time !== null"
                              :color="getStatusColor(item.status)"
                            >
                              {{ item.status | statusFormat }}
                            </font>
                            <font v-else>
                              N/A
                            </font>
                          </h4>
                          <h4 class="option-style">
                            Pending Reason:- <font
                              v-if="item.pending_reason == null"
                              class="font-my-style"
                            >
                              N/A
                            </font>
                            <font
                              v-else
                              class="font-my-style"
                            >
                              {{ item.pending_reason }}
                            </font>
                          </h4>
                          <h4 class="option-style">
                            Root Cause Of Issue:- <font
                              v-if="item.root_cause == null"
                              class="font-my-style"
                            >
                              N/A
                            </font>
                            <font
                              v-else
                              class="font-my-style"
                            >
                              {{ item.root_cause }}
                            </font>
                          </h4>
                        </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <br>
        </v-card>
        <br>
      </v-container>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';

  export default {
    name: 'ProjectIssueCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusFormat (status) {
        if (status === 'completed') {
          return 'Completed*';
        } else {
          return 'Pending';
        }
      },
    },
    props: {
      projectIssueId: {
        type: String,
        required: true,
      },
      projectIssueName: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        search: null,
        checkIns: {
          headers: [
            { text: 'Name', align: 'left', value: 'name' },
            { text: 'Company', value: 'company' },
            { text: 'Category', value: 'category' },
            { text: 'Check-In Type', value: 'checkin_type' },
            { text: 'Check-In Time', value: 'checkin_time' },
            { text: 'Check-In Gate', value: 'checkin_gate.name' },
            { text: 'Check-Out Time', value: 'checkout_time' },
            { text: 'Status', value: 'status' },
            { text: 'More', value: 'more' },
          ],
        },
        updateProgress: false,
        loading: false,
        expanded: [],
        singleExpand: true,
      };
    },
    computed: {
      paginatedRecordsStructure () {
        return this.$store.getters['projects/getProjectIssueCheckIns'];
      },
      projectIssueCheckIns () {
        const records = this.paginatedRecordsStructure;
        return records.data;
      },
      totalRecords () {
        if (Object.keys(this.paginatedRecordsStructure).length > 0) {
          return this.paginatedRecordsStructure.total;
        }
        return 0;
      },
      isSearchAdded () {
        if (this.search === null || this.search === '') {
          return false;
        }
        return true;
      },
      searchHint () {
        return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
      isSearchAdded (val) {
        if (val === false) {
          this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
        }
      },
    },
    async mounted () {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    methods: {
      async getAllRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        this.search = null;
        await this.$store.dispatch('projects/fetchProjectIssueCheckIns', {
          projectIssueId: this.projectIssueId,
          params: {
            params: {
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      async getRecordsFromServer ({ itemsPerPage, pageStart }) {
        this.updateProgress = true;
        await this.$store.dispatch('projects/fetchProjectIssueCheckIns', {
          projectIssueId: this.projectIssueId,
          params: {
            params: {
              name: this.search === '' ? null : this.search,
              limit: itemsPerPage,
              startPage: pageStart,
            },
          },
        }).catch(() => {
          this.updateProgress = false;
        });
        this.updateProgress = false;
      },
      getStatusColor (status) {
        if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      dailyWorkHrLimitReached (checkInDetails) {
        const checkInTime = new Date(checkInDetails.checkin_time).getTime();
        if (checkInDetails.checkout_time === null) {
          const currentTime = new Date().getTime();
          var diff = currentTime - checkInTime;
          var hrDiff = Math.floor(diff / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hrDiff) {
            return true;
          }
          return false;
        } else {
          const checkOutTime = new Date(checkInDetails.checkout_time).getTime();
          var totalWorkHrs = checkOutTime - checkInTime;
          var hours = Math.floor(totalWorkHrs / 3.6e6);
          if (checkInDetails.app_user.work_hours <= hours) {
            return true;
          }
          return false;
        }
      },
    },
  };
</script>

<style scoped>
.base-card {
  padding-right: 30px;
  padding-left: 30px;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.close-button {
  float: right;
  margin-right: 20px;
  cursor: pointer;
  color: #37474F;
}
.title-header {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  margin-left: 5px;
  color: #37474F;
  font-size: 18px;
}
.title-name {
  color: #FF3700;
}
@media (max-width: 960px) {
  .title-header {
    margin-top: 30px;
  }
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.my-tool-bar-title-style {
 font-size: 18px;
}
.my-toolbar-style {
  padding-left: 0px;
}
.strong-style {
  color: #37474F;
  font-weight: bold;
}
.my-title-style {
  font-size: 14px !important;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.option-style {
  font-size: 15px;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 5px;
  color: #546E7A;
}
.type-style {
  text-transform: capitalize;
}
.status-style{
  font-weight: bold;
}
.font-my-style {
  font-weight: bold;
  color: #37474F;
}
.my-type-style {
  text-transform: capitalize;
}
.search-style {
  color: #37474F;
  font-weight: 600;
}
.table-projects {
    border-radius: 0px !important;
    background: #fff;
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-top: none;
  }
  .project-list-card {
    box-shadow: none !important;
  }
  .table-projects tbody tr:nth-of-type(even) {
    background: #fff !important;
  }
  .table-projects tbody tr:nth-of-type(odd):hover {
    background: #fff !important;
  }
  .action-btn {
    background: #fff !important;
  }
  .filter-card {
    border: 1px solid #ccc !important;
    padding: 15px;
  }
  .table-top-border{
    border: 1px solid #ccc;
    border-bottom: none;
    background: #edf6f9;
  }
  .info-text{
    margin-bottom: 0px !important;
  }

.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
  padding-bottom: 10px;
}
</style>
